.App {
  align-self: center;
  justify-self: center;
}

#root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  height: 100vh;
  display: grid;
  font-size: 18px;
}

.container {
  min-width: 300px;
  background-color: #eee;
  text-align: center;
  padding: 27px 18px;
  margin-bottom: 27px;
}

h1 {
  margin: 0;
  padding-bottom: 18px;
  font-size: 18px;
}

.info {
  max-width: 21ch;
  margin: 0 auto;
  margin-bottom: 18px;
  background-color: #ddd;
  padding: 12px 24px;
  word-wrap: break-word;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 15px;
}

a, a:visited {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input,
button {
  padding: 9px;
  font-size: 18px;
  margin-bottom: 9px;
}

input.full-width {
  display: block;
  margin: 0 auto;
  margin-bottom: 9px;
  text-align: center;
}

select, option {
  background-color: #dddddd;
  color: black;
  border: none;
}